@charset "UTF-8";
/* 
 * Media query
 *
 * To use this, just nest the following inside your css declaration.
 *
 * @include breakpoint(* pm, lm, t, l, or lx. Choose your target screen size *) {
 *   New Declaration
 * }
 * 
 * For example:
 *
 * img {
 *   width: 680px;	
 *   @include breakpoint(pm) {
 *     width: 100%;
 *   }
 * }
 *
 * If you use Sublime Text, you can also download and use these snippets
 * https://github.com/chakler/scss-setup
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *:before, *:after {
  box-sizing: border-box; }

/*
 * How this works?
 *
 * We use the rem unit to maintain the vertical rhythm.
 *
 * At the Baseline setup below, we set the height of the vertical grid 
 * by declaring the height as font-size.
 * 
 * Once we get the vertical grid right, we use the rem unit 
 * to declare the height of any vertical properties like:
 * height, margin-top, margin-bottom, padding-top, padding-bottom
 *
 * For example:
 * If font-size at the baseline setup is 25px,
 * .box {padding: 1rem 15px} will be equals to .box {padding: 25px 15px}
 *
 * It is best to keep the rem value in 0.5 increments,
 * try to avoid values like 0.25rem or 0.75rem if possible.
 *
 */
html {
  font-family: "Inovato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 25px;
  line-height: 1rem; }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    html {
      font-size: 22px; } }
  @media only screen and (max-width: 479px) {
    html {
      font-size: 20px; } }

/*
 * Using vertical rhythm in type
 *
 * One important thing you have to remember is that
 * Don’t ever use px for declaring font-size and line-height
 *
 * Since we’re using vertical rhythm to maintain the vertical flow of the site,
 * the process of declaring line-height and font size will be a bit different.
 *
 * We always declare the determined line-height first.
 * This way we can make sure that the texts are following the vertical rhythm.
 * 
 * e.g. 
 *
 * p {
 *   line-height: 1rem;
 * }
 *
 * Then we determine the font size with this one simple formula:
 *
 * font-size = line-height / ratio
 * 
 * Common ratio for titles is 1.2 and for body texts is 1.4
 *
 * So let’s say we’re setting the font size for the body text, 
 * and the pre-determined line-height is 1rem, so:
 *
 * font-size = 1 / 1.4
 * 
 * Since we’re doing the math with sass, we need to give the result a rem unit, so:
 *
 * font-size = 1 / 1.4 * 1rem
 *
 * And hence:
 *
 * p {
 *   line-height: 1rem;
 *   font-size: 1 / 1.4 * 1rem;
 * }
 */
@font-face {
  font-family: 'Inovato';
  src: url("../font/Inovato-Regular.eot");
  src: url("../font/Inovato-Regular.eot?#iefix") format("embedded-opentype"), url("../font/Inovato-Regular.woff2") format("woff2"), url("../font/Inovato-Regular.woff") format("woff"), url("../font/Inovato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Inovato';
  src: url("../font/Inovato-Bold.eot");
  src: url("../font/Inovato-Bold.eot?#iefix") format("embedded-opentype"), url("../font/Inovato-Bold.woff2") format("woff2"), url("../font/Inovato-Bold.woff") format("woff"), url("../font/Inovato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

h1, h2, h3, h4, h5, h6 {
  font-family: "acumin-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 1rem;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased; }

h1 {
  line-height: 3rem;
  font-size: 2.5rem; }
  @media only screen and (max-width: 1139px) {
    h1 {
      line-height: 2rem;
      font-size: 1.6666666667rem; } }

h2 {
  line-height: 2.5rem;
  font-size: 2.0833333333rem; }
  @media only screen and (max-width: 1139px) {
    h2 {
      line-height: 1.5rem;
      font-size: 1.25rem; } }
  h2 + h3 {
    margin-top: 3rem; }

h3 {
  line-height: 2rem;
  font-size: 1.6666666667rem; }
  @media only screen and (max-width: 1139px) {
    h3 {
      line-height: 1.25rem;
      font-size: 1.0416666667rem; } }

h4 {
  line-height: 2rem;
  font-size: 1.4285714286rem; }
  @media only screen and (max-width: 1139px) {
    h4 {
      line-height: 1.25rem;
      font-size: 0.8928571429rem; } }

h5 {
  line-height: 1.5rem;
  font-size: 1.25rem; }

h6 {
  line-height: 1rem;
  font-size: 0.8333333333rem; }

small, .is-small {
  line-height: 0.8rem;
  font-size: 0.5714285714rem; }

p {
  line-height: 1rem;
  font-size: 0.7142857143rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern"; }
  p + *:not(p) {
    margin-top: 3rem; }
  p + ul {
    margin-top: 1rem; }

ul, ol {
  margin-bottom: 1rem;
  padding-left: 25px; }
  ul li, ol li {
    line-height: 1rem;
    font-size: 0.7142857143rem;
    font-weight: 400;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    position: relative; }
  ul + *:not(p), ol + *:not(p) {
    margin-top: 3rem; }

ul {
  list-style: none; }
  *:not(nav) > ul > li:before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 100%;
    left: -25px;
    top: 0;
    display: none; }

ol {
  list-style: decimal; }

a:focus, a:hover, a:active {
  text-decoration: none; }

.group {
  *zoom: 1; }
  .group:before, .group:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */ }
  .group:after {
    clear: both; }

.mainarea-contentsection > h1, .mainarea-contentsection > h2, .mainarea-contentsection > h3, .mainarea-contentsection > h4, .mainarea-contentsection > h5, .mainarea-contentsection > h6, .mainarea-contentsection > p {
  position: relative; }
  .mainarea-contentsection > h1:before, .mainarea-contentsection > h2:before, .mainarea-contentsection > h3:before, .mainarea-contentsection > h4:before, .mainarea-contentsection > h5:before, .mainarea-contentsection > h6:before, .mainarea-contentsection > p:before {
    font-size: 0.625rem;
    line-height: inherit;
    position: absolute;
    left: -45px;
    width: 30px;
    text-align: right;
    font-family: "Inovato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    opacity: 0.65; }
    @media only screen and (max-width: 479px) {
      .mainarea-contentsection > h1:before, .mainarea-contentsection > h2:before, .mainarea-contentsection > h3:before, .mainarea-contentsection > h4:before, .mainarea-contentsection > h5:before, .mainarea-contentsection > h6:before, .mainarea-contentsection > p:before {
        display: none; } }

.mainarea-contentsection > figure, .mainarea-contentsection > ul, .mainarea-contentsection > ol {
  position: relative; }
  .mainarea-contentsection > figure:before, .mainarea-contentsection > ul:before, .mainarea-contentsection > ol:before {
    font-size: 0.625rem;
    line-height: 1rem;
    position: absolute;
    left: -45px;
    width: 30px;
    text-align: right;
    font-family: "Inovato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    opacity: 0.65; }
    @media only screen and (max-width: 479px) {
      .mainarea-contentsection > figure:before, .mainarea-contentsection > ul:before, .mainarea-contentsection > ol:before {
        display: none; } }

.mainarea-contentsection > ul,
.mainarea-contentsection > ol {
  padding-left: 0; }
  .mainarea-contentsection > ul > li,
  .mainarea-contentsection > ol > li {
    padding-top: 0; }

.mainarea-contentsection > h1:before {
  content: "H1"; }

.mainarea-contentsection > h2:before {
  content: "H2"; }

.mainarea-contentsection > h3:before {
  content: "H3"; }

.mainarea-contentsection > h4:before {
  content: "H4"; }

.mainarea-contentsection > h5:before {
  content: "H5"; }

.mainarea-contentsection > h6:before {
  content: "H6"; }

.mainarea-contentsection > p:before {
  content: "P"; }

.mainarea-contentsection > p + p:before {
  content: ""; }

.mainarea-contentsection > figure:before {
  content: "IMG"; }

.mainarea-contentsection > ul:before {
  content: "UL"; }

.mainarea-contentsection > ol:before {
  content: "OL"; }

body {
  color: #3a3a3a;
  font-family: "Inovato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  ‑webkit‑text‑size‑adjust: 100%; }

img {
  max-width: 100%;
  margin-bottom: 1rem; }

.mainarea {
  min-height: 100vh;
  position: relative;
  overflow: hidden; }

.mainarea[data-main="black"] {
  background: #3a3a3a; }
  .mainarea[data-main="black"] > .mainarea-logo .shapes {
    fill: #3a3a3a; }
  .mainarea[data-main="black"] + nav li > a {
    color: #3a3a3a; }
  .mainarea[data-main="black"] + nav .nav-logo .shapes {
    fill: #3a3a3a; }
  .mainarea[data-main="black"] .button,
  .mainarea[data-main="black"] .button:hover {
    color: #3a3a3a; }
  .mainarea[data-main="black"][data-border="blue"] a, .mainarea[data-main="black"][data-border="red"] a {
    color: #efefef; }

.mainarea[data-main="white"] {
  background: #efefef; }
  .mainarea[data-main="white"] > .mainarea-logo .shapes {
    fill: #efefef; }
  .mainarea[data-main="white"] + nav li > a {
    color: #efefef; }
  .mainarea[data-main="white"] + nav .nav-logo .shapes {
    fill: #efefef; }
  .mainarea[data-main="white"] .button,
  .mainarea[data-main="white"] .button:hover {
    color: #efefef; }
  .mainarea[data-main="white"][data-border="yellow"] a,
  .mainarea[data-main="white"][data-border="yellow"] .button,
  .mainarea[data-main="white"][data-border="yellow"] .button:hover {
    color: #3a3a3a; }

.mainarea[data-main="yellow"] {
  background: #f7c535; }
  .mainarea[data-main="yellow"] > .mainarea-logo .shapes {
    fill: #f7c535; }
  .mainarea[data-main="yellow"] + nav li > a {
    color: #f7c535; }
  .mainarea[data-main="yellow"] + nav .nav-logo .shapes {
    fill: #f7c535; }
  .mainarea[data-main="yellow"] .button,
  .mainarea[data-main="yellow"] .button:hover {
    color: #f7c535; }

.mainarea[data-main="blue"] {
  background: #0d3aa1; }
  .mainarea[data-main="blue"] > .mainarea-logo .shapes {
    fill: #0d3aa1; }
  .mainarea[data-main="blue"] + nav li > a {
    color: #0d3aa1; }
  .mainarea[data-main="blue"] + nav .nav-logo .shapes {
    fill: #0d3aa1; }
  .mainarea[data-main="blue"] .button,
  .mainarea[data-main="blue"] .button:hover {
    color: #0d3aa1; }
  .mainarea[data-main="blue"][data-border="red"] a, .mainarea[data-main="blue"][data-border="black"] a {
    color: #efefef; }

.mainarea[data-main="red"] {
  background: #be2c2f; }
  .mainarea[data-main="red"] > .mainarea-logo .shapes {
    fill: #be2c2f; }
  .mainarea[data-main="red"] + nav li > a {
    color: #be2c2f; }
  .mainarea[data-main="red"] + nav .nav-logo .shapes {
    fill: #be2c2f; }
  .mainarea[data-main="red"] .button,
  .mainarea[data-main="red"] .button:hover {
    color: #be2c2f; }
  .mainarea[data-main="red"][data-border="blue"] a, .mainarea[data-main="red"][data-border="black"] a {
    color: #efefef; }

.mainarea[data-main="specialcolor"] {
  background: #F97E33; }
  .mainarea[data-main="specialcolor"] > .mainarea-logo .shapes {
    fill: #F97E33; }
  .mainarea[data-main="specialcolor"] + nav li > a {
    color: #F97E33; }
  .mainarea[data-main="specialcolor"] + nav .nav-logo .shapes {
    fill: #F97E33; }
  .mainarea[data-main="specialcolor"] .button,
  .mainarea[data-main="specialcolor"] .button:hover {
    color: #F97E33; }
  .mainarea[data-main="specialcolor"][data-border="blue"] a, .mainarea[data-main="specialcolor"][data-border="black"] a {
    color: #efefef; }
  .mainarea[data-main="specialcolor"][data-border="blue"] .button, .mainarea[data-main="specialcolor"][data-border="black"] .button {
    color: #3a3a3a;
    background: #efefef; }
  .mainarea[data-main="specialcolor"] > .mainarea-logo .shapes {
    fill: #3a3a3a; }
  .mainarea[data-main="specialcolor"] + nav .nav-logo .shapes {
    fill: #3a3a3a; }

.mainarea[data-main="specialcolor"][data-border="black"] > .mainarea-background > .mainarea-background-svg > .border {
  fill: url(#womentors-border-gradient); }

.mainarea[data-main="milktea"] {
  background: linear-gradient(135deg, #D72B27 0%, #F79075 100%); }
  .mainarea[data-main="milktea"] > .mainarea-logo .shapes {
    fill: #F79075; }
  .mainarea[data-main="milktea"] + nav li > a {
    color: #efefef; }
  .mainarea[data-main="milktea"] + nav .nav-logo .shapes {
    fill: #efefef; }
  .mainarea[data-main="milktea"] .button {
    color: #efefef; }
  .mainarea[data-main="milktea"] .button:hover {
    color: #3a3a3a; }

@media only screen and (min-width: 768px) and (max-width: 1139px) {
  .mainarea[data-border="black"] > .mainarea-logo .shapes {
    fill: #3a3a3a; } }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mainarea[data-border="black"] > .mainarea-logo .shapes {
    fill: #3a3a3a; } }

@media only screen and (max-width: 479px) {
  .mainarea[data-border="black"] > .mainarea-logo .shapes {
    fill: #3a3a3a; } }

.mainarea[data-border="black"] > .mainarea-background > .mainarea-background-svg > .border {
  fill: #3a3a3a; }

.mainarea[data-border="black"] + nav li > a {
  background: #3a3a3a; }
  .mainarea[data-border="black"] + nav li > a:hover {
    background: #686868; }

.mainarea[data-border="black"] a, .mainarea[data-border="black"] + nav > a {
  color: #efefef; }

.mainarea[data-border="black"] .menu-label {
  color: #3a3a3a; }

.mainarea[data-border="black"] .button {
  background: #3a3a3a; }

@media only screen and (min-width: 768px) and (max-width: 1139px) {
  .mainarea[data-border="white"] > .mainarea-logo .shapes {
    fill: #efefef; } }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mainarea[data-border="white"] > .mainarea-logo .shapes {
    fill: #efefef; } }

@media only screen and (max-width: 479px) {
  .mainarea[data-border="white"] > .mainarea-logo .shapes {
    fill: #efefef; } }

.mainarea[data-border="white"] > .mainarea-background > .mainarea-background-svg > .border {
  fill: #efefef; }

.mainarea[data-border="white"] + nav li > a {
  background: #efefef; }
  .mainarea[data-border="white"] + nav li > a:hover {
    background: white; }

.mainarea[data-border="white"] a, .mainarea[data-border="white"] + nav > a {
  color: #efefef; }

.mainarea[data-border="white"] .menu-label {
  color: #efefef; }

.mainarea[data-border="white"] .button {
  background: #efefef; }

@media only screen and (min-width: 768px) and (max-width: 1139px) {
  .mainarea[data-border="yellow"] > .mainarea-logo .shapes {
    fill: #f7c535; } }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mainarea[data-border="yellow"] > .mainarea-logo .shapes {
    fill: #f7c535; } }

@media only screen and (max-width: 479px) {
  .mainarea[data-border="yellow"] > .mainarea-logo .shapes {
    fill: #f7c535; } }

.mainarea[data-border="yellow"] > .mainarea-background > .mainarea-background-svg > .border {
  fill: #f7c535; }

.mainarea[data-border="yellow"] + nav li > a {
  background: #f7c535; }
  .mainarea[data-border="yellow"] + nav li > a:hover {
    background: #fade8d; }

.mainarea[data-border="yellow"] a, .mainarea[data-border="yellow"] + nav > a {
  color: #f7c535; }

.mainarea[data-border="yellow"] .menu-label {
  color: #f7c535; }

.mainarea[data-border="yellow"] .button {
  background: #f7c535; }

@media only screen and (min-width: 768px) and (max-width: 1139px) {
  .mainarea[data-border="specialcolor"] > .mainarea-logo .shapes {
    fill: #F97E33; } }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mainarea[data-border="specialcolor"] > .mainarea-logo .shapes {
    fill: #F97E33; } }

@media only screen and (max-width: 479px) {
  .mainarea[data-border="specialcolor"] > .mainarea-logo .shapes {
    fill: #F97E33; } }

.mainarea[data-border="specialcolor"] > .mainarea-background > .mainarea-background-svg > .border {
  fill: #F97E33; }

.mainarea[data-border="specialcolor"] + nav li > a {
  background: #F97E33; }
  .mainarea[data-border="specialcolor"] + nav li > a:hover {
    background: #fcb68c; }

.mainarea[data-border="specialcolor"] a, .mainarea[data-border="specialcolor"] + nav > a {
  color: #F97E33; }

.mainarea[data-border="specialcolor"] .menu-label {
  color: #F97E33; }

.mainarea[data-border="specialcolor"] .button {
  background: #F97E33;
  background: #efefef; }

.mainarea[data-border="specialcolor"] a {
  color: #efefef; }

@media only screen and (min-width: 768px) and (max-width: 1139px) {
  .mainarea[data-border="blue"] > .mainarea-logo .shapes {
    fill: #0d3aa1; } }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mainarea[data-border="blue"] > .mainarea-logo .shapes {
    fill: #0d3aa1; } }

@media only screen and (max-width: 479px) {
  .mainarea[data-border="blue"] > .mainarea-logo .shapes {
    fill: #0d3aa1; } }

.mainarea[data-border="blue"] > .mainarea-background > .mainarea-background-svg > .border {
  fill: #0d3aa1; }

.mainarea[data-border="blue"] + nav li > a {
  background: #0d3aa1; }
  .mainarea[data-border="blue"] + nav li > a:hover {
    background: #1d5ced; }

.mainarea[data-border="blue"] a, .mainarea[data-border="blue"] + nav > a {
  color: #0d3aa1; }

.mainarea[data-border="blue"] .menu-label {
  color: #0d3aa1; }

.mainarea[data-border="blue"] .button {
  background: #0d3aa1; }

.mainarea[data-border="blue"] hr {
  border-color: #0d3aa1;
  background: #0d3aa1; }

@media only screen and (min-width: 768px) and (max-width: 1139px) {
  .mainarea[data-border="proudcloudorange"] > .mainarea-logo .shapes {
    fill: #F97E33; } }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mainarea[data-border="proudcloudorange"] > .mainarea-logo .shapes {
    fill: #F97E33; } }

@media only screen and (max-width: 479px) {
  .mainarea[data-border="proudcloudorange"] > .mainarea-logo .shapes {
    fill: #F97E33; } }

.mainarea[data-border="proudcloudorange"] > .mainarea-background > .mainarea-background-svg > .border {
  fill: #F97E33; }

.mainarea[data-border="proudcloudorange"] + nav li > a {
  background: #F97E33; }
  .mainarea[data-border="proudcloudorange"] + nav li > a:hover {
    background: #fcb68c; }

.mainarea[data-border="proudcloudorange"] a, .mainarea[data-border="proudcloudorange"] + nav > a {
  color: #F97E33; }

.mainarea[data-border="proudcloudorange"] .menu-label {
  color: #F97E33; }

.mainarea[data-border="proudcloudorange"] .button {
  background: #F97E33; }

.mainarea[data-border="proudcloudorange"] hr {
  border-color: #F97E33;
  background: #F97E33; }

@media only screen and (min-width: 768px) and (max-width: 1139px) {
  .mainarea[data-border="red"] > .mainarea-logo .shapes {
    fill: #be2c2f; } }

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mainarea[data-border="red"] > .mainarea-logo .shapes {
    fill: #be2c2f; } }

@media only screen and (max-width: 479px) {
  .mainarea[data-border="red"] > .mainarea-logo .shapes {
    fill: #be2c2f; } }

.mainarea[data-border="red"] > .mainarea-background > .mainarea-background-svg > .border {
  fill: #be2c2f; }

.mainarea[data-border="red"] + nav li > a {
  background: #be2c2f; }
  .mainarea[data-border="red"] + nav li > a:hover {
    background: #dc696c; }

.mainarea[data-border="red"] a, .mainarea[data-border="red"] + nav > a {
  color: #be2c2f; }

.mainarea[data-border="red"] .menu-label {
  color: #be2c2f; }

.mainarea[data-border="red"] .button {
  background: #be2c2f; }

.mainarea[data-free="black"] > .mainarea-contentsection {
  color: #3a3a3a; }

.mainarea[data-free="black"] > .mainarea-background > .mainarea-background-svg > .free {
  fill: #3a3a3a; }

.mainarea[data-free="black"] + nav {
  color: #3a3a3a; }

.mainarea[data-free="black"] *:not(nav) > ul > li:before {
  background: #3a3a3a; }

.speakers .mainarea[data-free="black"] *:not(nav) > ul > li:after {
  border-right-color: #3a3a3a; }

.mainarea[data-free="black"] .button:hover {
  background: #3a3a3a; }

.mainarea[data-free="white"] > .mainarea-contentsection {
  color: #efefef; }

.mainarea[data-free="white"] > .mainarea-background > .mainarea-background-svg > .free {
  fill: #efefef; }

.mainarea[data-free="white"] + nav {
  color: #efefef; }

.mainarea[data-free="white"] *:not(nav) > ul > li:before {
  background: #efefef; }

.speakers .mainarea[data-free="white"] *:not(nav) > ul > li:after {
  border-right-color: #efefef; }

.mainarea[data-free="white"] .button:hover {
  background: #efefef; }

.mainarea[data-free="yellow"] > .mainarea-contentsection {
  color: #f7c535; }

.mainarea[data-free="yellow"] > .mainarea-background > .mainarea-background-svg > .free {
  fill: #f7c535; }

.mainarea[data-free="yellow"] + nav {
  color: #f7c535; }

.mainarea[data-free="yellow"] *:not(nav) > ul > li:before {
  background: #f7c535; }

.speakers .mainarea[data-free="yellow"] *:not(nav) > ul > li:after {
  border-right-color: #f7c535; }

.mainarea[data-free="yellow"] .button:hover {
  background: #f7c535; }

.mainarea[data-free="blue"] > .mainarea-contentsection {
  color: #0d3aa1; }

.mainarea[data-free="blue"] > .mainarea-background > .mainarea-background-svg > .free {
  fill: #0d3aa1; }

.mainarea[data-free="blue"] + nav {
  color: #0d3aa1; }

.mainarea[data-free="blue"] *:not(nav) > ul > li:before {
  background: #0d3aa1; }

.speakers .mainarea[data-free="blue"] *:not(nav) > ul > li:after {
  border-right-color: #0d3aa1; }

.mainarea[data-free="blue"] .button:hover {
  background: #0d3aa1; }

.mainarea[data-free="red"] > .mainarea-contentsection {
  color: #be2c2f; }

.mainarea[data-free="red"] > .mainarea-background > .mainarea-background-svg > .free {
  fill: #be2c2f; }

.mainarea[data-free="red"] + nav {
  color: #be2c2f; }

.mainarea[data-free="red"] *:not(nav) > ul > li:before {
  background: #be2c2f; }

.speakers .mainarea[data-free="red"] *:not(nav) > ul > li:after {
  border-right-color: #be2c2f; }

.mainarea[data-free="red"] .button:hover {
  background: #be2c2f; }

.mainarea > .mainarea-contentsection > h1 {
  color: #0d3aa1; }

.mainarea > .mainarea-contentsection > h2 {
  color: #be2c2f; }

.mainarea[data-text="black"] > .mainarea-contentsection {
  color: #3a3a3a; }

.mainarea[data-text="black"] .mainarea-logo .shapes,
.mainarea[data-text="black"] + nav .nav-logo .shapes {
  fill: #3a3a3a; }

.mainarea[data-text="black"] a,
.mainarea[data-text="black"] + nav li > a {
  color: #3a3a3a; }

.mainarea-logo .ffc-logo {
  position: fixed;
  right: 2.5rem;
  bottom: 2.5rem;
  z-index: 30; }
  .mainarea-logo .ffc-logo svg {
    width: 3rem;
    height: 3rem; }
  @media only screen and (min-width: 768px) and (max-width: 1139px) {
    .mainarea-logo .ffc-logo {
      display: none; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .mainarea-logo .ffc-logo {
      display: none; } }
  @media only screen and (max-width: 479px) {
    .mainarea-logo .ffc-logo {
      display: none; } }

.mainarea-contentsection {
  padding: 3rem 40vw 7rem 60px;
  position: relative;
  z-index: 20; }
  .mainarea-contentsection > figure > img {
    border: 0.5rem solid; }
  @media only screen and (min-width: 1140px) {
    .mainarea-contentsection {
      min-height: 100vh; } }
  @media only screen and (max-width: 939px) and (min-width: 768px) {
    .mainarea-contentsection {
      padding-right: 14vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1139px) {
    .mainarea-contentsection {
      padding: 2rem 30vw 55vw 60px; }
      .mainarea[data-theme="greater"] .mainarea-contentsection, .mainarea[data-theme="parenthesis"] .mainarea-contentsection {
        padding: 2rem 20vw 65vw 60px; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .mainarea-contentsection {
      padding: 2rem 60px 70vw; } }
  @media only screen and (max-width: 479px) {
    .mainarea-contentsection {
      padding: 1.5rem 20px 78vw; } }

.mainarea-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10; }
  @media only screen and (min-width: 768px) and (max-width: 1139px) {
    .mainarea-background {
      position: static;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      width: 100%; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .mainarea-background {
      position: static;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      width: 100%; } }
  @media only screen and (max-width: 479px) {
    .mainarea-background {
      position: static;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      width: 100%; } }

.mainarea-background-svg {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0; }
  @media only screen and (min-width: 1140px) and (max-width: 1599px) {
    .mainarea-background-svg {
      right: -100px; } }
  @media only screen and (min-width: 768px) and (max-width: 1139px) {
    .mainarea-background-svg {
      height: 100vw;
      transform: rotate(90deg);
      transform-origin: bottom right;
      top: auto;
      right: 100vw;
      bottom: -15vw; }
      .mainarea[data-theme="greater"] .mainarea-background-svg, .mainarea[data-theme="parenthesis"] .mainarea-background-svg {
        height: auto;
        width: 62vw;
        transform: rotate(0deg);
        top: auto;
        right: 0;
        bottom: 0; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .mainarea-background-svg {
      height: 100vw;
      transform: rotate(90deg);
      transform-origin: bottom right;
      top: auto;
      right: 100vw;
      bottom: 0; }
      .mainarea[data-theme="greater"] .mainarea-background-svg, .mainarea[data-theme="parenthesis"] .mainarea-background-svg {
        height: auto;
        width: 62vw;
        transform: rotate(0deg);
        top: auto;
        right: 0;
        bottom: 0; } }
  @media only screen and (max-width: 479px) {
    .mainarea-background-svg {
      height: 100vw;
      transform: rotate(90deg);
      transform-origin: bottom right;
      top: auto;
      right: 100vw;
      bottom: 0; }
      .mainarea[data-theme="greater"] .mainarea-background-svg, .mainarea[data-theme="parenthesis"] .mainarea-background-svg {
        height: auto;
        width: 62vw;
        transform: rotate(0deg);
        top: auto;
        right: 0;
        bottom: 0; } }

nav {
  font-family: "acumin-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  position: fixed;
  bottom: 2.5rem;
  left: 60px;
  z-index: 40;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  text-align: right; }
  @media only screen and (min-width: 768px) and (max-width: 1139px) {
    nav:before {
      display: none; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    nav:before {
      display: none; } }
  @media only screen and (max-width: 479px) {
    nav:before {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 1139px) {
    nav {
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem 60px 1rem 60px; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    nav {
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem 60px 1rem 60px; } }
  @media only screen and (max-width: 479px) {
    nav {
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem 20px 1rem 20px; } }

nav > ul {
  padding: 0;
  margin: 0; }
  @media only screen and (min-width: 768px) and (max-width: 1139px) {
    nav > ul {
      display: inline-block;
      vertical-align: middle; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    nav > ul {
      display: inline-block;
      vertical-align: middle; } }
  @media only screen and (max-width: 479px) {
    nav > ul {
      display: inline-block;
      vertical-align: middle; } }

nav > ul > li {
  display: inline-block;
  padding: 0;
  margin: 0.5rem 0 0; }
  @media only screen and (min-width: 768px) and (max-width: 1139px) {
    nav > ul > li {
      margin: 0; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    nav > ul > li {
      margin: 0; } }
  @media only screen and (max-width: 479px) {
    nav > ul > li {
      margin: 0; } }

nav > ul > li > a {
  display: block;
  padding: 0.5rem 10px;
  margin-right: 5px;
  text-decoration: none;
  font-weight: 800; }

.nav-logo {
  display: none; }
  @media only screen and (min-width: 768px) and (max-width: 1139px) {
    .nav-logo {
      display: inline-block;
      vertical-align: middle; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .nav-logo {
      display: inline-block;
      vertical-align: middle; } }
  @media only screen and (max-width: 479px) {
    .nav-logo {
      display: inline-block;
      vertical-align: middle; } }

.nav-logo svg {
  width: 2rem;
  height: 2rem; }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes bounce {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(-18px); } }

.logo, .mainarea-contentsection, nav {
  opacity: 0;
  animation: fade-in ease-in 1 0.3s;
  animation-fill-mode: forwards;
  animation-transition: 0.3s ease-in-out opacity; }

.logo {
  animation-delay: 0.2s; }

.mainarea-contentsection {
  animation-delay: 0.4s; }

nav {
  animation-delay: 0.8s; }

h3 + h4 {
  margin-top: -.5rem; }

h2, h3, h4, h5, h6 {
  clear: both; }

figure.speaker-img,
figure.lightning-img {
  width: 180px;
  margin: 0 15px 0 0; }
  figure.speaker-img img,
  figure.lightning-img img {
    display: block;
    margin-bottom: 0; }
  @media only screen and (max-width: 479px) {
    figure.speaker-img,
    figure.lightning-img {
      margin: 0 0 1rem; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    figure.speaker-img,
    figure.lightning-img {
      margin: 0 0 1rem; } }
  @media only screen and (max-width: 767px) {
    figure.speaker-img,
    figure.lightning-img {
      margin: 0 0 1rem; } }

figure.lightning-img {
  width: 100px; }

.speakers *:not(nav) > ul > li {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap; }
  .speakers *:not(nav) > ul > li > h3, .speakers *:not(nav) > ul > li h4 {
    width: 100%;
    flex: 1 1 auto; }
  .speakers *:not(nav) > ul > li > p {
    width: calc(100% - 195px); }
    @media only screen and (max-width: 479px) {
      .speakers *:not(nav) > ul > li > p {
        width: 100%; } }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      .speakers *:not(nav) > ul > li > p {
        width: 100%; } }
    @media only screen and (max-width: 767px) {
      .speakers *:not(nav) > ul > li > p {
        width: 100%; } }

.speakers *:not(nav) > ul > li:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  bottom: -0.7rem;
  left: -25px;
  border: 20px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 0px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 50%;
  display: block; }

#sponsors {
  margin-top: 10rem; }
  @media only screen and (max-width: 767px) {
    #sponsors {
      text-align: center; } }

@media only screen and (max-width: 767px) {
  .sponsor-row {
    text-align: center; } }

.partner {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.5rem;
  margin-top: 1rem; }
  .partner:last-child {
    margin-right: 0; }
  @media only screen and (max-width: 767px) {
    .partner {
      margin-left: .5rem;
      margin-right: .5rem; } }
  .partner img {
    max-width: none;
    margin-bottom: 0; }

.registerinfo.is-bottom {
  margin-top: 3rem; }
  @media screen and (max-width: 767px) {
    .registerinfo.is-bottom br {
      display: none; } }
  @media screen and (min-width: 1140px) and (min-height: 720px) {
    .registerinfo.is-bottom {
      position: absolute;
      bottom: 3rem;
      left: 60px;
      right: 40vw;
      margin-top: 0;
      margin-bottom: 0; } }
  .registerinfo.is-bottom .button {
    margin-top: 0.5rem; }

.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-right: 5px;
  margin-bottom: 0.5rem;
  font-family: "acumin-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 800;
  font-variant-numeric: tabular-nums; }

hr {
  margin-bottom: 3rem;
  border: 0;
  border-bottom: 5px solid #efefef;
  background: #efefef; }
